@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }
}
#projects {
  opacity: 1;
  transition: opacity 0.5s ease;
}

.profile {
  width: 190px;
  margin-left: 50px;
  height: 277px;
  zoom: 90%;
}

.totop {
  position: fixed;
  z-index: 9999;
  right: 120px;
  padding: 12px;
  background: red;
  bottom: 20px;
  display: absolute;
}

.imgtest:hover {
  width: 1002px;
  height: 1000px;
}
.svg-container svg {
  padding-right: 10px;
  height: 55px;
}

/*image from https://www.freepik.com/ */
body {
  background-color: rgb(250, 250, 250);
  background-image: url(/public/9039819.jpg);
  background-repeat:no-repeat;
  background-position:bottom left;
  background-size: 400px 500px;
  zoom:80%;
}
@media only screen and (max-width: 1011px) {
  body {
    background-image: none;
  }
}
